// eslint-disable-next-line import/no-webpack-loader-syntax
import LOGO from '!!raw-loader!./logo.txt';
import './index.css';

function Rules() {
  return (
    <section className="rules">
        <h1>$ cat HOWTO.md</h1>

        <pre className="logo">{ LOGO }</pre>

        <ul>
            <li>The addresses approving transactions are <strong>static</strong>, you cannot change them later. Lost keys = lost funds!</li>
            <li>Call <strong>withdraw()</strong> twice (from 2 different addresses) and specify <strong>exactly the same</strong> token, amount and payee to withdraw funds</li>
            <li>Use <strong>0x0000000000000000000000000000000000000000</strong> as token address for Ether approvals, and ERC-20 <strong>contract address</strong> for ERC-20 approvals</li>
            <li>Amount is not scaled, e.g. to withdraw 1 Ether put <strong>1000000000000000000</strong> under amount</li>
            <li>Call <strong>withdraw()</strong> with zero amount to retract an approval</li>
            <li>PRO TIP: use Etherscan's "Write Contract" feature to approve withdrawals (no, we don't have a UI)</li>
            <li>Only <strong>one</strong> pending withdrawal is supported, latest approval wins</li>
        </ul>
    </section>
  );
}

export default Rules;
