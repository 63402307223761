// eslint-disable-next-line import/no-webpack-loader-syntax
import LOGO from '!!raw-loader!./logo.txt';
import './index.css';

function Code() {
  return (
    <section className="code">
        <h1>$ cat MultiSig.sol</h1>

        <pre dangerouslySetInnerHTML={ { __html: LOGO } }></pre>

        <h1>$ exit</h1>

        <pre>[Process completed]</pre>
    </section>
  );
}

export default Code;
