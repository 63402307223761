import { useCallback } from 'react';
import Hero from './components/hero';
import Deploy from './components/deploy';
import Rules from './components/rules';
import Code from './components/code';

import './App.css';

function App() {
  const setRef = useCallback((ref) => {
    ref?.focus();
  }, []);

  return (
    <div className="app" ref={setRef} tabIndex="0">
      <Hero />
      <Rules />
      <Deploy />
      <Code />
    </div>
  );
}

export default App;
