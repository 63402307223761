// eslint-disable-next-line import/no-webpack-loader-syntax
import LOGO from '!!raw-loader!./logo.txt';
import './index.css';

function Hero() {
  return (
    <section className="hero">
        <h1>$ cat README.md</h1>

        <pre className="logo">{ LOGO }</pre>

        <ul>
            <li>Simple, easy-to-understand smart contract to secure your funds. Free and open-source</li>
        </ul>

        <ul>
            <li>Deploy <strong>your own</strong> smart contract with 3 <strong>fixed</strong> Ethereum addresses allowed to approve withdrawals</li>
            <li>Send <strong>Ethers</strong> or <strong>ERC-20</strong> tokens to contract's address</li>
            <li>Approve every withdrawal from <strong>any 2 addresses</strong> to take funds out</li>
        </ul>
    </section>
  );
}

export default Hero;
