import { useState, useCallback, useEffect, useMemo } from 'react';
import { useWallet } from './hooks';

// eslint-disable-next-line import/no-webpack-loader-syntax
import LOGO from '!!raw-loader!./logo.txt';

import './index.css';

const BLOCK_EXPLORERS = {
    1: '',
    5: 'goerli.',
    11155111: 'sepolia.'
};

const ADDRESS_REGEXP = /0x[0-9a-fA-F]{40}/;

const VERIFY_URL = 'https://{CHAIN}etherscan.io/address/{ADDRESS}';

function validateAddresses(addresses) {
    return addresses.every((address) => address.match(ADDRESS_REGEXP)) && (new Set([...addresses]).size === 3);
}

function Deploy() {
    const [wallet1, setWallet1] = useState('');
    const [wallet2, setWallet2] = useState('');
    const [wallet3, setWallet3] = useState('');
    const [ready, setReady] = useState(false);
    const [deploying, setDeploying] = useState(false);
    const [deployedAddress, setDeployedAddress] = useState(null);
    const [deployedChainId, setDeployedChainId] = useState(null);
    const api = useWallet();

    const handleChange = useCallback((event) => {
        const setter = { wallet1: setWallet1, wallet2: setWallet2, wallet3: setWallet3 }[event.target.name];
        setter(event.target.value);
    }, []);

    const handleDeploy = useCallback(async () => {
        setDeployedAddress(null);
        setDeployedChainId(null);

        try {
            setDeploying(true);

            const [deployedAddress, deployedChainId] = await api.deploy([wallet1, wallet2, wallet3]);

            setDeployedAddress(deployedAddress);
            setDeployedChainId(deployedChainId);
        } catch (ex) {
            console.error(ex);
        } finally {
            setDeploying(false);
        }
    }, [api, wallet1, wallet2, wallet3]);

    const blockExplorerUrl = useMemo(() => {
        if (deployedAddress !== null && deployedChainId !== null) {
          return VERIFY_URL.replace('{CHAIN}', BLOCK_EXPLORERS[deployedChainId] || '').replace('{ADDRESS}', deployedAddress);
        } else {
          return null;
        }
    }, [deployedAddress, deployedChainId]);

    useEffect(() => {
        const ready = validateAddresses([wallet1, wallet2, wallet3]);
        setReady(ready);

        if (!ready) {
          setDeployedAddress(null);
          setDeployedChainId(null);
        }
    }, [api, wallet1, wallet2, wallet3]);

    const isWaitingForWeb3 = api.web3 && api.deploy === null;
    const isWeb3Ready = api.web3 && api.deploy !== null && !deploying;
    const isDeploying = api.web3 && deploying;
    const isWeb3Missing = !api.web3;

    return (
        <section className="deploy" id="deploy">
        <h1>$ ./deploy.sh</h1>

        <pre className="logo">{ LOGO }</pre>

        <ul>
            <li>Type 3 <strong>unique &amp; valid</strong> addresses below</li>
            <li>Address #1: <input maxlength="42" autocomplete="off" placeholder="0x________________________________________" type="text" name="wallet1" value={wallet1} onChange={handleChange}/></li>
            <li>Address #2: <input maxlength="42" autocomplete="off" placeholder="0x________________________________________" type="text" name="wallet2" value={wallet2} onChange={handleChange}/></li>
            <li>Address #3: <input maxlength="42" autocomplete="off" placeholder="0x________________________________________" type="text" name="wallet3" value={wallet3} onChange={handleChange}/></li>
        </ul>

        {
          ready &&
          <ul>
            { isWeb3Ready && <li>Click "Deploy" and sign the transaction using Metamask:</li> }
            { isWeb3Ready && <li><button onClick={handleDeploy}>[ DEPLOY ]</button></li> }
            { isWaitingForWeb3 && <li>Waiting for Metamask...</li> }
          </ul>
        }

        { isDeploying && <span>[ DEPLOYMENT IN PROGRESS... ]</span> }
        { isWeb3Missing && <span>[ NO WEB3 DETECTED - INSTALL METAMASK ]</span> }

        {
          blockExplorerUrl &&
          <ul>
              <li>Your contract has been created successfully. Link to block explorer below:</li>
              <li><a href={blockExplorerUrl} rel="noreferrer" target="_blank">[ OPEN IN ETHERSCRAN.IO ]</a></li>
          </ul>
        }
    </section>
    );
}

export default Deploy;
