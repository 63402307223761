import { useEffect, useState } from 'react';
import detectProvider from "@metamask/detect-provider";
import { ContractFactory, BrowserProvider } from 'ethers';

import CONTRACT from '../../constants/contract.json';

function chooseProvider(provider) {
    if (provider?.providers?.length > 0) {
        return provider.providers.find(({ isMetaMask }) => isMetaMask) || provider.providers[0];
    }

    return provider;
}

export function useWallet() {
    const [api, setApi] = useState({ deploy: null, web3: false });

    useEffect(() => {
        detectProvider().then(candidates => {
            const provider = chooseProvider(candidates);
            const web3 = !!provider;

            const deploy = async (addresses) => {
                const [chainId] = await Promise.all([
                    provider.request({ method: "eth_chainId" }),
                    provider.request({ method: "eth_requestAccounts" })
                ]);

                const signer = await new BrowserProvider(window.ethereum, "any").getSigner();
                const factory = new ContractFactory(CONTRACT.abi, CONTRACT.bytecode, signer);

                const result = await factory.deploy(addresses);
                await result.deploymentTransaction().wait(2);

                return [ result.target, parseInt(chainId, 16) ];
            };

            setApi((current) => ({ ...current, deploy, web3 }));
        }).catch(() => setApi((current) => ({ ...current, web3: false })));
    }, [])

    return api;
}
